import React from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <img
          src="../primeway-logo-back.svg"
          width={30}
          height={30}
          alt="logo"
        />
        <h1>PrimeWay</h1>
      </div>
      <p className={styles.name}>PrimeWay</p>
      <p>Санкт-Петербург</p>

      <div className={styles.footerBottom}>
        <div>
          <span>
            <span className={styles.span}>©</span> 2025 PrimeWay
            <a
              href="/user-agreement"
              target="_blank"
              rel="noopener noreferrer"
              style={{marginLeft:'15px'}}
            >
              Пользовательское соглашение
            </a>
          </span>
        </div>
        <div className={styles.icon}>
          <img src="./tg.svg" alt="telegram" width={40} height={37} />
          <img src="./yandex.svg" alt="yandexmail" width={40} height={40} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
