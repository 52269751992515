// App.js
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Container from "./components/Container/Container";
import Header from "./components/Header/Header";
import Content from "./components/Content/Content";
import CustomCursor from "./components/CustomCursor/CustomCursor";
import Footer from "./components/Footer/Footer";
import UserAgreement from "./components/UserAgreement/UserAgreement";

function App() {
  const location = useLocation();
  const isUserAgreementPage = location.pathname === "/user-agreement";

  return (
    <Container>
      <CustomCursor />
      {!isUserAgreementPage && <Header />}

      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/user-agreement" element={<UserAgreement />} />
      </Routes>

      {!isUserAgreementPage && <Footer />}
    </Container>
  );
}

export default App;
