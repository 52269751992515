import React from "react";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src="../primeway-logo-back.svg" width={50} height={50} alt="Logo" />
          <h2>PrimeWay</h2>
        </div>
        <nav className={styles.navLinks}>
          <a href="https://platform.primeway.io" target="_blank" rel="noopener noreferrer">
            <p className={styles.p}>Платформа</p>
          </a>
          <a href="https://platform.primeway.io/docs" target="_blank" rel="noopener noreferrer">
            <p className={styles.p}>Документация</p>
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;