import React from "react";
import styles from "./UserAgreement.module.scss";

const UserAgreement = () => {
  return (
    <div className={styles.main}>
      <h1>Пользовательское соглашение</h1>
      <div className={styles.mainText}>
        <p>
          Welcome to the website for Together Computer, which is owned and
          operated by Together Computer Inc., a Delaware corporation (the
          “Company,” “we,” or “us”). The Company has developed and makes
          available programmatic APIs and web interfaces to host, use, fine tune
          and train large AI models (the “Services”). The Company may also
          provide training, migration or other professional services to you
          (“Professional Support”). These Terms of Service (the “Agreement”)
          govern your use of www.together.xyz (the “Website”), the Services, and
          Supplemental Support. Additionally, you may enter into an addendum to
          this Agreement with the Company, or an order form with the Company,
          both of which incorporate this Agreement by reference. By using the
          Services, the Supplemental Support, or this Website, you agree to be
          bound by this Agreement. This Agreement is effective when you commence
          using the Services, the Supplemental Support, or the Website (the
          "Effective Date"). This Agreement may be subject to change in the
          future.
        </p>
        <h2>1. Services</h2>
        <div className={styles.pContetn}>
          <p>
            1. Subject to this Agreement, the Company hereby grants you a
            non-exclusive right to access and use the Services. As part of the
            Services, the Company may provide you with certain application
            programming interfaces (APIs), API access tokens, HTML scripts, data
            import tools, or other software as applicable (collectively,
            “APIs”). You are entirely responsible for provisioning and managing
            your user’s accounts and your user’s compliance with this Agreement.
            <br />
            2. The Services are subject to modification and change. No
            guarantees are made with respect to the Services’ quality,
            stability, uptime or reliability, unless otherwise agreed between
            the parties in an Order Form. <br />
            3. The Company will maintain a security program in accordance with
            industry standards that is designed to (i) ensure the security and
            integrity of Customer Data; (ii) protect against threats or hazards
            to the security or integrity of Customer Data; and (iii) prevent
            unauthorized access to Customer Data. “Customer Data” means any
            data, content or materials that you or your users submit to the
            Services. <br />
            4. The Company may monitor your use of the Services to assess
            compliance with this Agreement and the quality of operations of the
            Services and to make improvements to the Services.
          </p>
        </div>
        <h2>2. Your Responsibilities</h2>
        <div className={styles.pContetn}>
          <p>
            1. Subject to this Agreement, the Company hereby grants you a
            non-exclusive right to access and use the Services. As part of the
            Services, the Company may provide you with certain application
            programming interfaces (APIs), API access tokens, HTML scripts, data
            import tools, or other software as applicable (collectively,
            “APIs”). You are entirely responsible for provisioning and managing
            your user’s accounts and your user’s compliance with this Agreement.
            <br />
            2. The Services are subject to modification and change. No
            guarantees are made with respect to the Services’ quality,
            stability, uptime or reliability, unless otherwise agreed between
            the parties in an Order Form. <br />
            3. The Company will maintain a security program in accordance with
            industry standards that is designed to (i) ensure the security and
            integrity of Customer Data; (ii) protect against threats or hazards
            to the security or integrity of Customer Data; and (iii) prevent
            unauthorized access to Customer Data. “Customer Data” means any
            data, content or materials that you or your users submit to the
            Services. <br />
            4. The Company may monitor your use of the Services to assess
            compliance with this Agreement and the quality of operations of the
            Services and to make improvements to the Services.
          </p>
        </div>
        <h2>3. Professional Services</h2>
        <div className={styles.pContetn}>
          <p>
            1. Subject to this Agreement, the Company hereby grants you a
            non-exclusive right to access and use the Services. As part of the
            Services, the Company may provide you with certain application
            programming interfaces (APIs), API access tokens, HTML scripts, data
            import tools, or other software as applicable (collectively,
            “APIs”). You are entirely responsible for provisioning and managing
            your user’s accounts and your user’s compliance with this Agreement.
          </p>
        </div>
        <h2>6. DMCA Copyright Infringement Notices and Counternotices</h2>
        <div className={styles.pContetn}>
          <p>
            We have implemented the procedures described in the Digital
            Millennium Copyright Act of 1998 (“DMCA”), 17 U.S.C. § 512,
            regarding the reporting of alleged copyright infringement and the
            removal of or disabling access to the infringing material. If you
            have a good faith belief that copyrighted material on the Services
            is being used in a way that infringes the copyright over which you
            are authorized to act, you may make a Notice of Infringing Material.
            <br /> Before serving a Notice of Infringing Material, you may wish
            to contact a lawyer to better understand your rights and obligations
            under the DMCA and other applicable laws. For example, if your
            Notice fails to comply with all requirements of sections 512(c)(3),
            your Notice may not be effective.
            <br />
            <br />
            Notices must be sent to:
            <br />
            DMCA Agent: Takedowns <br />
            Together Computer Inc.(together.xyz)
            <br />
            Address: 801 El Camino Real, Menlo Park, CA 94025
            <br />
            Email: takedowns@together.xyz
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserAgreement;
