import React from "react";
import styles from "./Content.module.scss";
import GPUList from "../GPUList/GPUList";
import Workflow from "../Workflow/Workflow";
import OpenModels from "../OpenModels/OpenModels";
import FAQ from "../FAQ/FAQ";

const Content = () => {
  return (
    <main className={styles.main}>
      <h1 className={styles.h1}>
        Запускайте и деплойте проекты машинного обучения на <br /> бессерверных
        GPU -{" "}
        <span
          style={{
            background: "linear-gradient(to right, #00FF37 0%, #054CFF 100%)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          всего одной <br /> CLI командой
        </span>
      </h1>

      <p className={styles.p}>
        Автомасштабирование к нулю opens source моделей во время отсутствия
        запросов, планирование расписания работы задач <br /> дообучения, деплоя
        и других всего одним параметром в конфиге.
        <br /> - <br />
        Вам не нужно знать ничего об облачных технологий и devops для создания
        экономичных и эффективных ml систем
      </p>
      <button
        onClick={() => window.open("https://platform.primeway.io", "_blank")}
        className={styles.MlButton}
      >
        Начните создавать великолепные ml приложения
      </button>

      <div className={styles.imgContainer}>
        <img
          className={styles.imgTop}
          src="../Screenshot1.png"
          alt="platform"
        />
        <img className={styles.imgBottom} src="../Screenshot2.png" alt="code" />
      </div>
      <h2 className={styles.h2}>Вы платите только за время работы <br /> контейнера</h2>
      <p className={styles.pInfo}>Это значит, вам не нужно тратить деньги на настройку сервера и загрузка докер образа на <br />сервер. Биллинг включается только после того, как контейнер с образом запустился</p>

      <GPUList />

      <Workflow />

      <div className={styles.quote}>
        <p>Готово! Теперь у вас запущенная, одна из лучших на русском языке, open source llm модель <br /> от tbank, созданная на основе qwen2 с расписанием, когда она должна работать, и <br /> автомасштабированием к нулю после 15 минут без запросов и поднятием во время нового</p>
      </div>

      <OpenModels />

      <FAQ />
    </main>
  );
};

export default Content;
