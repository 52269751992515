import React, { useState } from 'react';
import './FAQ.scss'; 

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    { question: 'Как PrimeWay отличается от других облачных сервисов?', answer: 'Мы предлагаем более широкий ассортимент GPU, включая те, которые недоступны у крупных облачных провайдеров. Мы создали serverless first решение, в котором вам не нужно беспокоиться не о чем, кроме своего кода' },
    { question: 'Могу ли я отслеживать прогресс выполнения моей задачи?', answer: 'Да, вы можете в реальном времени следить за логами и статусом задачи через наш дашборд или CLI.' },
    { question: 'Можно ли использовать PrimeWay в продакшене?', answer: 'Да, PrimeWay идеально подходит для выполнения рабочих задач с высокой нагрузкой, предлагая гибкие ресурсы и высокую надежность.' },
    { question: 'Можно ли использовать PrimeWay в моем MLOps конвейере?', answer: 'Да, PrimeWay легко интегрируется в существующие MLOps конвейеры, автоматизируя процессы тренировки и развертывания моделей.' },
    { question: 'Насколько легко начать работу с PrimeWay?', answer: 'Очень просто. Установите python библиотеку через pip, настройте YAML файл, и одной командой запустите ваш проект на удалённом GPU.' },
    { question: 'Какие проекты машинного обучения можно запускать на PrimeWay?', answer: 'PrimeWay поддерживает различные задачи машинного обучения — от тренировки LLM и других моделей до батч инференса любых модулей. Поддерживаются фреймворки и модели любых видов, которые работают на nvidia gpu' },
    { question: 'Что происходит, если моя задача не выполняется успешно?', answer: 'Вы получите доступ к подробным логам ошибок через дашборд или CLI, что поможет устранить проблему и перезапустить задачу.' },
    { question: 'Есть ли возможность немедленно прервать выполнение задачи?', answer: 'Да, если вы поняли по логам, что в коде есть какие-то ошибки, вы можете немедленно остановить работу задачи и списание средств прекратится в тот же момент' },
    { question: 'Могу ли я вывести средства, если мне это нужно?', answer: 'Да, вы можете запросить вывод неиспользованных средств в любой момент, если поймете, что PrimeWay вам не подходит.' },
    { question: 'Насколько безопасны мои данные при использовании PrimeWay?', answer: 'Мы применяем шифрование по индустриальным стандартам для защиты ваших данных как при хранении, так и при передаче, обеспечивая высокий уровень безопасности.' },
    { question: 'Могу ли я получить артефакты выполнения задачи?', answer: 'Да, после завершения работы вы сможете скачать все данные, созданные в ходе выполнения задачи, через дашборд или CLI.' }
  ];
  
  

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    
    <div className="faq-container">
      <h2>FAQs</h2>
      <ul className="faq-list hover-target">
        {faqs.map((faq, index) => (
          <li key={index} className="faq-item">
            <div 
              className={`faq-question ${activeIndex === index ? 'active' : ''}`} 
              onClick={() => toggleFAQ(index)}
            >
              <span>{faq.question}</span>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'open' : ''}`}>
              {faq.answer}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
