import React, { useEffect, useRef } from 'react';
import './CustomCursor.scss';

const CustomCursor = () => {
  const cursorRef = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;

    let request;

    const moveCursor = (e) => {
      const x = e.clientX;
      const y = e.clientY;

      cancelAnimationFrame(request);
      request = requestAnimationFrame(() => {
        cursor.style.left = x + 'px';
        cursor.style.top = y + 'px';
      });
    };

    document.addEventListener('mousemove', moveCursor);

    // Обработка наведения на ссылки, кнопки и элементы с классом 'hover-target'
    const hoverTargets = document.querySelectorAll('a, button, .hover-target');

    const handleMouseEnter = () => {
      cursor.classList.add('hover');
    };

    const handleMouseLeave = () => {
      cursor.classList.remove('hover');
    };

    // Добавляем обработчики событий
    hoverTargets.forEach((target) => {
      target.addEventListener('mouseenter', handleMouseEnter);
      target.addEventListener('mouseleave', handleMouseLeave);
    });

    // Очистка обработчиков при размонтировании компонента
    return () => {
      cancelAnimationFrame(request);
      document.removeEventListener('mousemove', moveCursor);

      hoverTargets.forEach((target) => {
        target.removeEventListener('mouseenter', handleMouseEnter);
        target.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  return <div id="custom-cursor" ref={cursorRef}></div>;
};

export default CustomCursor;