import React from "react";
import styles from './OpenModels.module.scss';

const OpenModels = () => {
  const topCardsData = [
    {
      id: 1,
      image: './meta-color.svg',
      title: 'LIama 3.1 8B',
      tag: 'Chat'
    },
    {
      id: 2,
      image: './mistral-color.svg',
      title: 'Mistral 7B',
      tag: 'Chat'
    }
  ];

  const bottomCardsData = [
    {
      id: 1,
      image: './mistral-color.svg',
      title: 'Mixtral 8x7B',
      tag: 'Chat'
    },
    {
      id: 2,
      image: './meta-color.svg',
      title: 'LIama 3.1 70B',
      tag: 'Chat'
    },
    {
      id: 3,
      image: './meta-color.svg',
      title: 'LIama 3.1 405B',
      tag: 'Chat'
    },
    {
      id: 4,
      image: './mistral-color.svg',
      title: 'Mixtral 8x22B',
      tag: 'Chat'
    },
    {
      id: 5,
      image: './deepseek-color.svg',
      title: 'Deepseek Coder',
      tag: 'Chat'
    },
    {
      id: 6,
      image: './google-color.svg',
      title: 'Gemma 2 27B',
      tag: 'Chat'
    },
    {
      id: 7,
      image: './meta-color.svg',
      title: 'Codellama 34B',
      tag: 'code'
    },
    {
      id: 8,
      image: './deepseek-color.svg',
      title: 'Deepseek Coder',
      tag: 'chat'
    },
    {
      id: 9,
      image: './deepseek-color.svg',
      title: 'Deepseek Coder',
      tag: 'chat'
    },
    {
      id: 10,
      image: './plus.svg',
      title: 'and more!',
      tag: '100+ LLMs supported'
    }
  ];

  // Функция для обработки клика на карточку
  const handleCardClick = () => {
    window.open('https://platform.primeway.io/docs', '_blank');
  };

  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <div className={styles.text}>
          <h1 className={styles.h1}>
            Запускайте все, что угодно, что <br /> может быть запущено в докере
          </h1>
          <p>
            Деплойте и дообучайте open source модели вместе с <br />
            PrimeWay, чтобы сделать это максимально эффективно
          </p>
        </div>
        <div className={styles.cardsRight}>
          {topCardsData.map(card => (
            <div
              key={card.id}
              className={`${styles.card} hover-target`}
              onClick={handleCardClick}
            >
              <img src={card.image} alt={card.title} />
              <p>{card.title}</p>
              <span>{card.tag}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.bottomSection}>
        {bottomCardsData.map(card => (
          <div
            key={card.id}
            className={`${styles.card} hover-target`}
            onClick={handleCardClick}
          >
            <img src={card.image} alt={card.title} />
            <p>{card.title}</p>
            <span>{card.tag}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OpenModels;