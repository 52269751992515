import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NvidiaIcon from "./NvidiaIcon";

const gpuData = [
  {
    id: 1,
    name: "A100 PCIe",
    memoryInGb: 80,
    costPerHour: 260,
  },
  {
    id: 2,
    name: "A100 SXM",
    memoryInGb: 80,
    costPerHour: 299,
  },
  {
    id: 3,
    name: "A40",
    memoryInGb: 48,
    costPerHour: 90,
  },
  {
    id: 4,
    name: "RTX 4090",
    memoryInGb: 24,
    costPerHour: 130,
  },
  {
    id: 5,
    name: "H100 NVL",
    memoryInGb: 94,
    costPerHour: 355,
  },
  {
    id: 6,
    name: "H100 PCIe",
    memoryInGb: 80,
    costPerHour: 355,
  },
  {
    id: 7,
    name: "H200 SXM",
    memoryInGb: 143,
    costPerHour: 460,
  },
  {
    id: 8,
    name: "L4",
    memoryInGb: 24,
    costPerHour: 90,
  },
  {
    id: 9,
    name: "L40S",
    memoryInGb: 48,
    costPerHour: 175,
  },
  {
    id: 10,
    name: "RTX 2000 Ada",
    memoryInGb: 16,
    costPerHour: 55,
  },
  {
    id: 11,
    name: "RTX 6000 Ada",
    memoryInGb: 48,
    costPerHour: 140,
  },
  {
    id: 12,
    name: "RTX A6000",
    memoryInGb: 48,
    costPerHour: 130,
  },
  {
    id: 14,
    name: "H100 SXM",
    memoryInGb: 80,
    costPerHour: 399,
  },
];

const GPUList = () => {
  // Состояние для отслеживания, какой GPU был скопирован
  const [copiedGpuId, setCopiedGpuId] = useState(null);

  // Функция копирования имени GPU
  const handleCopy = (gpu) => {
    navigator.clipboard.writeText(gpu.name).then(() => {
      setCopiedGpuId(gpu.id);

      // Сбрасываем состояние через 2 секунды
      setTimeout(() => {
        setCopiedGpuId(null);
      }, 2000);
    });
  };

  return (
    <Box sx={{ marginTop: "60px" }}>
      {/* Сетка Grid с 5 столбцами */}
      <Grid container spacing={2} columns={5} alignItems="center">
        {/* Текстовый блок, занимающий 2 столбца */}
        <Grid item xs={5} sm={2}>
          <Typography
            sx={{
              fontFamily: "Jura, serif",
              fontWeight: "700",
              fontSize: { xs: "28px", sm: "34px" },
              textAlign: { xs: "center", md: "left" },
              background: "linear-gradient(to right, #06E528 0%, #6606F5 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            То, чего нет у других облаков - и дешевле, то что есть
          </Typography>
        </Grid>

        {/* Первые 3 карточки GPU, каждая занимает 1 столбец */}
        {gpuData.slice(0, 3).map((gpu) => (
          <Grid item key={gpu.id} xs={5} sm={1}>
            <Tooltip
              title={copiedGpuId === gpu.id ? "Имя скопировано!" : ""}
              open={copiedGpuId === gpu.id}
              placement="top"
              arrow
              disableHoverListener
              disableFocusListener
              disableTouchListener
            >
              <Card
                elevation={0}
                className="hover-target"
                sx={{
                  position: "relative",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  border: "1px solid #D8D8D8",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover .copy-icon": {
                    opacity: 1,
                  },
                }}
                onClick={() => handleCopy(gpu)}
              >
                {/* Иконка копирования */}

                <IconButton
                  className="copy-icon"
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    opacity: 0,
                    transition: "opacity 0.3s",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopy(gpu);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>

                <CardContent
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "8px",
                  }}
                >
                  <NvidiaIcon />

                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "bold" }}
                    component="div"
                    gutterBottom
                  >
                    {gpu.name}
                  </Typography>

                  <Typography sx={{ fontSize: "16px" }} color="text.secondary">
                    <strong>Память:</strong> {gpu.memoryInGb} GB
                  </Typography>

                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: 1, mt: 1 }}
                  >
                    <Box
                      component="span"
                      sx={{
                        color: "rgb(81, 126, 24)",
                        padding: "5px 20px",
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderRadius: "5px",
                        fontSize: "15px",
                      }}
                    >
                      <strong>{gpu.costPerHour}</strong> ₽/час
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>
        ))}

        {/* Остальные карточки GPU, располагаются под верхними, по 5 в строке */}
        {gpuData.slice(3).map((gpu) => (
          <Grid item key={gpu.id} xs={5} sm={1}>
            <Tooltip
              title={copiedGpuId === gpu.id ? "Имя скопировано!" : ""}
              open={copiedGpuId === gpu.id}
              placement="top"
              arrow
              disableHoverListener
              disableFocusListener
              disableTouchListener
            >
              <Card
                elevation={0}
                className="hover-target"
                sx={{
                  position: "relative",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  border: "1px solid #D8D8D8",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover .copy-icon": {
                    opacity: 1,
                  },
                }}
                onClick={() => handleCopy(gpu)}
              >
                {/* Иконка копирования */}

                <IconButton
                  className="copy-icon"
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    opacity: 0,
                    transition: "opacity 0.3s",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopy(gpu);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>

                <CardContent
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "8px",
                  }}
                >
                  <NvidiaIcon />

                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "bold" }}
                    component="div"
                    gutterBottom
                  >
                    {gpu.name}
                  </Typography>

                  <Typography sx={{ fontSize: "16px" }} color="text.secondary">
                    <strong>Память:</strong> {gpu.memoryInGb} GB
                  </Typography>

                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: 1, mt: 1 }}
                  >
                    <Box
                      component="span"
                      sx={{
                        color: "rgb(81, 126, 24)",
                        padding: "5px 20px",
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderRadius: "5px",
                        fontSize: "15px",
                      }}
                    >
                      <strong>{gpu.costPerHour}</strong> ₽/час
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GPUList;
